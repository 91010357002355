import React from 'react';
import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import Cachepage from "./cachepage"
import Validation from "./validation"
import List from "./list"

// document.write=function(s){
//     var scripts = document.getElementsByTagName('script');
//     var lastScript = scripts[scripts.length-1];
//     lastScript.insertAdjacentHTML("beforebegin", s);
// }

class App extends React.Component {
  constructor(props) {
    super(props);

    this.validProps = {};
  }

  // 1271

  registerValidProps = (validProps) => {
    this.validProps = validProps
  }

  componentDidMount() {
    this.props.history.listen( location =>  {
      if (location.pathname != "/" && location.pathname != "/validation") {
        window.postMessage({
          type: 'LS_EXT_NAVIGATE',
          url: window.location.href
        }, window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : ""));
      }
    });

    sessionStorage.setItem("baseurl", window.location.href);

    window.addEventListener("beforeunload", function(e){
      sessionStorage.clear();
    }, false);

    // let renderStepOrderId = sessionStorage.getItem('renderStepOrderId');
    // if (!renderStepOrderId) {
    //   renderStepOrderId = 0;
    //   sessionStorage.setItem('renderStepOrderId', renderStepOrderId);
    // } else {
    //   sessionStorage.setItem('renderStepOrderId', parseInt(renderStepOrderId) + 1);
    // }
  }

  render() {
    return (
        <div>
          <Switch>
            <Route path={"/validation"} render={() => <Validation validProps={this.validProps} />} />
            <Route path="/:cachepageId" render={() => <Cachepage registerValidProps={this.registerValidProps} />} key={Date.now()} />
            <Route path="/" render={() => <Cachepage registerValidProps={this.registerValidProps} cachepageId={window.location.href.includes("2204") ? 2204 : 2203} />} />
          </Switch>
        </div>
    );
  }
}

export default withRouter(App);
