import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import parse from "html-react-parser";
import InnerHTML from "dangerously-set-html-content";
import cheerio from "cheerio";
import { UAParser } from "ua-parser-js";
import { useParams } from "react-router-dom/cjs/react-router-dom";

var parser = new UAParser();

const fluidPlayerReady = () => {
  var event = new CustomEvent('fluidPlayerReadyEvent');
  document.dispatchEvent(event);
}

window.fluidPlayerReady = fluidPlayerReady;

const shuffle = (arr) => {
  return arr
    .map((a) => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value);
};

const articleList = (baseUrl) => {
  // revolt
  if (baseUrl.includes("2204")) {
    return [
      {
        id: "revolt_1",
        title: "Leonna Hale, mass shootings, and how to protect Black citizens",
        img: "https://www.revolt.tv/wp-content/uploads/2022/06/GettyImages-1248100720-e1655131947553-1586x891.jpg?theia_smart_thumbnails_file_version=2",
      },
      {
        id: "revolt_2",
        title: "Kamala Harris denounces Republican leaders during Pride speech",
        img: "https://www.revolt.tv/wp-content/uploads/2022/06/GettyImages-1402558375-1-e1655148669660-1586x891.jpg",
      },
      {
        id: "revolt_3",
        title:
          "Kickin' Facts with Legendary Lade | Heron Preston x BAPE Sta sneaker",
        img: "https://www.revolt.tv/wp-content/uploads/2022/06/YEN9690-e1655138321893-1586x891.jpg",
      },
      {
        id: "revolt_4",
        title:
          "Mr. Eazi believes afrobeats could take over as the biggest music genre in the world",
        img: "https://www.revolt.tv/wp-content/uploads/2022/06/GettyImages-1136943725-e1654878753617-1586x891.jpg",
      },
      {
        id: "revolt_5",
        title:
          "Black Lives Matter donations, Black mental health, and the baby formula shortage",
        img: "https://www.revolt.tv/wp-content/uploads/2022/05/GettyImages-1228248156-e1654005945235-1586x891.jpg",
      },
      {
        id: "revolt_6",
        title:
          "Alleged Proud Boys storm Drag Queen Story Hour at Bay Area library",
        img: "https://www.revolt.tv/wp-content/uploads/2022/06/GettyImages-1077957640-1586x891.jpg",
      },
      {
        id: "revolt_7",
        title:
          'Mike Tyson was "blown away" when his daughter confronted Boosie Badazz over his transphobic comments',
        img: "https://www.revolt.tv/wp-content/uploads/2022/06/Image-from-iOS-5-e1654964111974-1586x891.jpg",
      },
      {
        id: "revolt_8",
        title:
          "Bobby Shmurda is focused on business domination and isn't letting anything stop him",
        img: "https://www.revolt.tv/wp-content/uploads/2022/05/GettyImages-1370270267-e1652834270196-1586x891.jpg",
      },
      {
        id: "revolt_9",
        title:
          "The Buffalo shooting aftermath, Biggie Smalls, and Kevin Samuels' impact on Black community",
        img: "https://www.revolt.tv/wp-content/uploads/2022/05/GettyImages-1398122405-1-1586x891.jpg",
      },
      {
        id: "revolt_10",
        title:
          "31 members of white supremacist group arrested and charged with planning riot at Idaho Pride event",
        img: "https://www.revolt.tv/wp-content/uploads/2022/06/GettyImages-1357193050-e1655053728478-1586x891.jpg",
      },
      {
        id: "revolt_11",
        title:
          "World Wide Web3 | How fashion is quickly taking over the metaverse",
        img: "https://www.revolt.tv/wp-content/uploads/2022/06/GettyImages-1396504304-1586x891.jpg",
      },
      {
        id: "revolt_12",
        title:
          'Kelly Rowland says she would play late legend Donna Summer "in a heartbeat"',
        img: "https://www.revolt.tv/wp-content/uploads/2022/05/GettyImages-1393886918-e1652796223630-1586x891.jpg",
      },
      {
        id: "revolt_13",
        title:
          'Diddy elaborates on his issue with cancel culture: "People deserve a second chance"',
        img: "https://www.revolt.tv/wp-content/uploads/2022/05/GettyImages-1397429008-e1652711700416-1586x891.jpg",
      },
      {
        id: "revolt_14",
        title:
          "Ohio city limits the amount of force police can use against nonviolent protesters",
        img: "https://www.revolt.tv/wp-content/uploads/2022/06/GettyImages-1216833083-e1654713702512-1586x891.jpg",
      },
      {
        id: "revolt_15",
        title:
          "9 best moments of Yung Miami’s “Caresha Please” sneak peek episode with Diddy",
        img: "https://www.revolt.tv/wp-content/uploads/2022/06/Image-from-iOS-3-e1654873025930-1586x891.jpg",
      },
      {
        id: "revolt_16",
        title:
          'Nick Cannon reveals his upcoming R&B mixtape is "as raw as it gets"',
        img: "https://www.revolt.tv/wp-content/uploads/2022/03/GettyImages-1346445308-e1646501403441.jpg",
      },
      {
        id: "revolt_17",
        title: "Should Black Twitter be concerned with an Elon Musk takeover?",
        img: "https://www.revolt.tv/wp-content/uploads/2022/05/GettyImages-1395371348-e1652101808119-1586x891.jpg",
      },
      {
        id: "revolt_18",
        title:
          "Ohio House Republicans pass bill banning transgender girls from high school and college female sports teams",
        img: "https://www.revolt.tv/wp-content/uploads/2022/06/GettyImages-1233645256-e1654352158247-1586x891.jpg",
      },
      {
        id: "revolt_19",
        title:
          "Studio Sessions | Problem discovered “a quiet confidence” after Snoop Dogg told him he’s “supposed to be here”",
        img: "https://www.revolt.tv/wp-content/uploads/2022/06/Problem96-e1654784926309-1586x891.jpg",
      },
      {
        id: "revolt_20",
        title:
          "Nichole Lynel explains how trusting her gut helped her thrive as a businesswoman",
        img: "https://www.revolt.tv/wp-content/uploads/2022/03/GettyImages-1356704521-e1648580087355.jpg",
      },
    ];
  } else if (baseUrl.includes("2203")) {
    return [
      {
        id: "blavity_1",
        title:
          "Myles Frost Makes History As Youngest Solo Tony Award Winner For His Portrayal Of Michael Jackson",
        img: "https://cms.blavity.com/wp-content/uploads/2022/06/GettyImages-9001402570784-compress.jpg",
      },
      {
        id: "blavity_2",
        title:
          "Breaking Generational Curses And 'Re-Raising' Ourselves Is Burdensome AF",
        img: "https://cms.blavity.com/wp-content/uploads/2022/06/GettyImages-1187591660-Cropped-compress.jpg",
      },
      {
        id: "blavity_3",
        title:
          "Addressing The Digital Divide: 5 Tips On How To Accelerate Digital Equity",
        img: "https://cms.blavity.com/wp-content/uploads/2022/03/BNxHP_5_header-compress.png",
      },
      {
        id: "blavity_4",
        title:
          "Russell Wilson Delivers Surprise For Ciara, Sends A Message To The Haters: 'Stay Squared Up'",
        img: "https://cms.blavity.com/wp-content/uploads/2022/06/GettyImages-1241104512-Cropped-scaled.jpg",
      },
      {
        id: "blavity_5",
        title: "Bipartisan Senate Group Reaches Agreement On Gun Reforms",
        img: "https://cms.blavity.com/wp-content/uploads/2022/06/Gun_Control_Katie_Godowski-compress.jpg",
      },
      {
        id: "blavity_6",
        title:
          "Why It Matters That Marvel Introduced A 19-Year-Old Muslim Woman As A Superhero",
        img: "https://cms.blavity.com/wp-content/uploads/2022/06/Ms-Marvel-muslim-girls-women-leadership-compress.jpg",
      },
      {
        id: "blavity_7",
        title:
          "The U.S. Department Of Education Approved $415 Million In Student Loan Forgiveness",
        img: "https://res.cloudinary.com/blavity/image/upload/g_center,w_800,q_auto:best,g_south_east,x_0/v1645216004/zfzjdrk4xiomdqzwp00j",
      },
      {
        id: "blavity_8",
        title: "Lil Nas X's Fans Roast Him After He Posts Picture At Taco Bell",
        img: "https://cms.blavity.com/wp-content/uploads/2022/06/GettyImages-1389380504-Cropped-compress.jpg",
      },
      {
        id: "blavity_9",
        title:
          "Hurricane Chris Responds To Artist Who Sampled His Music: 'She Never Involved Me, So We Even'",
        img: "https://cms.blavity.com/wp-content/uploads/2022/06/GettyImages-9001347042000-compress.jpg",
      },
      {
        id: "blavity_10",
        title:
          "Why Investing In Black Tech Hubs Could Be The Formula To Economic Equity",
        img: "https://cms.blavity.com/wp-content/uploads/2022/06/Black-man-and-woman-coding-tech-hubs-equity-compress.jpg",
      },
      {
        id: "blavity_11",
        title:
          "Meet Journey Carter, Prairie View A&M Student And Fashion Designer Behind The Journey Collection",
        img: "https://cms.blavity.com/wp-content/uploads/2022/02/IMG_2841901-compress.jpg",
      },
      {
        id: "blavity_12",
        title:
          "Someone Questioned Bernie Mac's Humor And Was Quickly Shut Down",
        img: "https://cms.blavity.com/wp-content/uploads/2022/06/GettyImages-106855600-Cropped-compress.jpg",
      },
      {
        id: "blavity_13",
        title:
          "US Postal Service Sued For Allegedly Seizing Black Lives Matter Masks Amid Protests",
        img: "https://cms.blavity.com/wp-content/uploads/2022/06/GettyImages-1228086135-Cropped-compress.jpg",
      },
      {
        id: "blavity_14",
        title: "Why Does The 'T' Stand Alone In LGBTQ+?",
        img: "https://cms.blavity.com/wp-content/uploads/2022/06/GettyImages-696076352-Cropped-compress.jpg",
      },
      {
        id: "blavity_15",
        title:
          "One Million Black Women: How Goldman Sachs Is Investing In Black Women And Creating A Sustainable Future For Everyone",
        img: "https://res.cloudinary.com/blavity/image/upload/g_center,w_800,q_auto:best,g_south_east,x_0/v1639670265/lqbka483f1rbwtzsiviq",
      },
      {
        id: "blavity_16",
        title: "Missy Elliott And Janet Jackson Prove They're BFF Goals",
        img: "https://cms.blavity.com/wp-content/uploads/2022/06/Screen-Shot-2022-06-09-at-12.46.46-PM-Cropped-compress.png",
      },
      {
        id: "blavity_17",
        title:
          "'Caresha Please' Breaks Viewership Records During Premiere Episode",
        img: "https://cms.blavity.com/wp-content/uploads/2022/06/GettyImages-1391751599-Cropped-compress.jpg",
      },
      {
        id: "blavity_18",
        title:
          "As A Survivor Of A Hate Crime, We Can No Longer Allow Tech Companies To Be A Vessel Of Hate",
        img: "https://cms.blavity.com/wp-content/uploads/2022/05/hate-crime-online-social-media-real-world-Black-woman-smartphone-compress.jpg",
      },
      {
        id: "blavity_19",
        title:
          "We Outside: 10 Influencers Inspiring Us To Ditch The Dumbbell And Head Out For Our Workouts",
        img: "https://res.cloudinary.com/blavity/image/upload/g_center,w_800,q_auto:best,g_south_east,x_0/v1633036326/wdf8xd6rbcjy4kjoey6n",
      },
      {
        id: "blavity_20",
        title:
          "A Detroit Woman Goes Viral After Casually Taking Shots With Drake At A Bar",
        img: "https://cms.blavity.com/wp-content/uploads/2022/06/GettyImages-1386394933-Cropped-scaled.jpg",
      },
    ];
  } else {
    return [];
  }
};

const videoList = (baseUrl) => {
  // revolt
  if (baseUrl.includes("2204")) {
    return [
      {
        id: "bald_beautiful.mp4"
      },
      {
        id: "fashion_forward.mp4"
      },
      {
        id: "lucky_daye.mp4"
      }
    ]
  } else if (baseUrl.includes("2203")) {
    return [
      {
        id: "ashlee_marie_preston.mp4"
      },
      {
        id: "baltimore_mayor.mp4"
      },
      {
        id: "daryl_davis.mp4"
      },
    ]
  }
  else {
    return []
  }
}

const removeLinks = () => {
  var links = document.querySelectorAll(
    'a:not([data-toggle="collapse"]):not([class*="lumenAllowedLink"]), *[type="submit"]'
  );

  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener("click", function (e) {
      //prevent event action
      e.preventDefault();
      e.stopImmediatePropagation();
    });
    links[i].addEventListener("contextmenu", function (e) {
      //prevent event action
      e.preventDefault();
      e.stopImmediatePropagation();
    });
  }
};

const getStepOrderId = () => {
  let renderSteps = JSON.parse(sessionStorage.getItem("renderSteps"));
  if (!renderSteps) {
    renderSteps = [window.FROM_SERVER.stepDetails].concat(window.FROM_SERVER.incompleteStepDetails);
    sessionStorage.setItem("renderSteps", JSON.stringify(renderSteps));
  }

  return (
    renderSteps.filter(
      (step) => step.url == (window.location.origin + window.location.pathname)
    )[parseInt(sessionStorage.getItem("renderStepOrderId"))].order
  );
};

class Cachepage extends React.Component {
  constructor(props) {
    super(props);

    var searchParams = new URLSearchParams(window.location.search);

    this.state = {
      html: null,
      parsedHtml: null,
    };
    this.participantId =
      !!window.FROM_SERVER && searchParams.get("session_id") != "2337_debug"
        ? window.FROM_SERVER.participant_id
        : 1193669;
    this.stepOrderId =
      !!window.FROM_SERVER && searchParams.get("session_id") != "2337_debug"
        ? getStepOrderId()
        : 3;
    this.baseUrl =
      sessionStorage.getItem("baseurl") || window.location.href;
    this.articleList = articleList(this.baseUrl);
    this.videoList = videoList(this.baseUrl);
  }

  activateFluidPlayer = async () => {
    if (!!document.querySelector("#lumenFluidPlayer")) {
      let studyId = 10908; // STUDY ID MIGHT CHANGE
      let adList = [];
      const vastContent = await (
        await fetch(
          `https://api.lumen-research.com/v1.0/participant_stim_server/${studyId}/${this.participantId}/${this.stepOrderId}/1/vast/`
        )
      ).text();

      if (!vastContent.includes("No Stims Found")) {
        adList = [{
          roll: vastContent.includes("_Mid") ? "midRoll" : "preRoll",
          timer: 30,
          vastTag: `https://api.lumen-research.com/v1.0/participant_stim_server/${studyId}/${this.participantId}/${this.stepOrderId}/1/vast/`,
          adText: "",
          adClickable: false,
        }]
      }

      /* eslint-disable */
      const flplayer = window.fluidPlayer("LRPlayer", {
        layoutControls: {
          controlBar: {
            autoHideTimeout: 3,
            animated: true,
            autoHide: false,
          },
          htmlOnPauseBlock: {
            html: null,
            height: null,
            width: null,
          },
          autoPlay: true,
          mute: true,
          keyboardControls: false,
          allowTheatre: false,
          playPauseAnimation: false,
          playbackRateEnabled: false,
          allowDownload: false,
          playButtonShowing: true,
          fillToContainer: true,
          posterImage: "",
          doubleclickFullscreen: false,
        },
        vastOptions: {
          adList: adList,
          adCTAText: false,
          adCTATextPosition: "",
          vastAdvanced: {
            vastVideoEndedCallback: () => {
              console.log("fluid player ad end")
              window.postMessage(
                {
                  type: "LS_YT_SHOW_NEXT",
                  url: window.location.href,
                },
                window.location.protocol +
                  "//" +
                  window.location.hostname +
                  (window.location.port ? ":" + window.location.port : "")
              );
            },
            vastLoadedCallback: () => {
              setTimeout(() => document.querySelector(".fluid_controls_container").className += " initial_controls_show", 1000)
            },
          }
        },
      });
      /* eslint-enable */
    }
  };

  formatListView = async (loaded) => {
    let renderStepOrderId = sessionStorage.getItem("renderStepOrderId");
    if (!renderStepOrderId) {
      renderStepOrderId = 0;
      sessionStorage.setItem("renderStepOrderId", renderStepOrderId);
    } else {
      sessionStorage.setItem(
        "renderStepOrderId",
        parseInt(renderStepOrderId) + 1
      );
    }

    loaded(".ntktdv2").remove();
    let newArticleArr = [];
    let seenItems = JSON.parse(sessionStorage.getItem("seenItems"));

    this.articleList
      .filter((i) => !seenItems || !seenItems.includes(i.id.toString()))
      .forEach((art) => {
        if (this.baseUrl.includes(2204)) {
          let clonedArticle = cheerio.load(
            loaded.html(loaded(".row.card.list-view.type-small:first-child"))
          );
          clonedArticle("a").attr("lumen-href", "/" + art.id);
          clonedArticle("a").attr("href", null);
          clonedArticle("a").addClass("lumenAllowedLink");
          clonedArticle("a").css("cursor", "pointer");
          clonedArticle("a:not([aria-label])").text(art.title);
          clonedArticle(".item-image").css(
            "background-image",
            `url(${art.img})`
          );
          // clonedArticle('.item-image').attr("style", null);
          // clonedArticle('img').parent().css("overflow", "hidden");
          clonedArticle(".text-14-18").remove();
          //clonedArticle('p[class^="finance-ticker-fetch-success"]').css("display", "none");
          // clonedArticle('p[class^="finance-ticker-fetch-success"]').text(art.title);
          newArticleArr.push(clonedArticle);
          loaded(".row.card.list-view.type-small").remove();
          shuffle(newArticleArr).forEach((art) => {
            loaded(".can_load_more").append(art.html());
          });
        } else if (this.baseUrl.includes(2203)) {
          let clonedArticle = cheerio.load(
            loaded.html(loaded("#replacestories > li:first-child"))
          );
          clonedArticle("a").attr("lumen-href", "/" + art.id);
          clonedArticle("a").attr("href", null);
          clonedArticle("a").addClass("lumenAllowedLink");
          clonedArticle("a").css("cursor", "pointer");
          clonedArticle('a:not([aria-hidden="true"])').text(art.title);
          clonedArticle("img").attr("src", art.img);
          clonedArticle("img").parent().css("overflow", "hidden");
          clonedArticle("div[data-test-locator]").remove();
          newArticleArr.push(clonedArticle);
          loaded("#replacestories > li").remove();
          shuffle(newArticleArr).forEach((art) => {
            loaded("#replacestories").append(art.html());
          });
        }
      });
  };

  getPage = async ({ applyProcessing = true }) => {
    // const html = (await (await fetch("https://content.lumen-research.com/cachepages/" + this.props.cachepageId + ".html")).text());
    const html = await (
      await fetch(
        "https://s3.eu-west-2.amazonaws.com/ctt-media/cachepages/" +
          this.props.cachepageId +
          ".html"
      )
    ).text();
    // For local testing
    // const html = await (
    //   await fetch(
    //     "https://127.0.0.1/" +
    //       this.props.cachepageId +
    //       ".html"
    //   )
    // ).text();
    const loaded = cheerio.load(html);
    window.loaded = loaded;

    if(!applyProcessing) return loaded.html();

    // deal with existing scripts
    let scriptSrcs = [];
    loaded(
      'script[src^="https://api.lumen-research.com/v1.0/cachepage_server"]'
    ).each((i, node) => {
      scriptSrcs.push(node.attribs.src);
    });
    for (const src of scriptSrcs) {
      let scriptContent = await (await fetch(src)).text();
      loaded(`script[src="${src}"]`).replaceWith(
        scriptContent.slice(16).slice(0, -3)
      );
    }
    loaded(`base`).attr("href", window.location.origin);

    // deal with custom lumen tags
    let lumenStimPlacement = [];
    loaded("lumenstim").each((i, node) => {
      // replace with fetched source
      lumenStimPlacement.push(node.attribs["placement-id"]);
    });
    for (const placement of lumenStimPlacement) {
      let studyId = 10908; // STUDY ID MIGHT CHANGE
      let lumenStim = await (
        await fetch(
          `https://api.lumen-research.com/v1.0/participant_stim_server/${studyId}/${this.participantId}/${this.stepOrderId}/${placement}/`
        )
      ).text();
      //lumenStim = lumenStim.replaceAll("DOMContentLoaded", "load")
      if (
        !!lumenStim.match(/{play_on(.*)\(\)}/) &&
        parser.getOS().name == "iOS"
      ) {
        lumenStim = lumenStim.replace(
          `document.addEventListener("DOMContentLoaded", function() {`,
          ""
        );
        lumenStim = lumenStim.replace(`});`, "");
      }
      let lumenAd = cheerio.load(
        lumenStim.slice(67).slice(0, -3).replaceAll("'+'", "")
      );
      lumenAd("video").attr("loop", "true");
      if (parser.getOS().name == "iOS") {
        lumenAd("video").removeAttr("autoplay");
      }
      loaded(`lumenstim[placement-id="${placement}"]`).replaceWith(
        lumenAd.html()
      );
    }

    loaded(".caas-yvideo-wrapper").each((i, node) => {
      if (i != 0) {
        loaded(node).remove();
      }
    });

    if (this.props.cachepageId === 2204 || this.props.cachepageId === 2203) {
      await this.formatListView(loaded);
    }

    // make sure lumen stims resize themselves properly
    loaded("img[id^='lumen']").css("max-width", "100%");
    loaded("video[id^='lumen']").css("max-width", "100%");

    loaded(".caas-3p-blocked").remove();

    

    if (loaded(".player").length > 0) {
      loaded("head").append("<script src='https://content.lumen-research.com/cache_page_data/fluidplayer.min.js'></script>");
      loaded(".player").attr("id", "lumenFluidPlayer");

      let source = "";
  
      let seenVids = JSON.parse(sessionStorage.getItem("seenVids"));
      if (!seenVids) {
        source = this.videoList[Math.floor(Math.random() * this.videoList.length)].id;
        sessionStorage.setItem("seenVids", JSON.stringify([source]));
      } else {
        let filtered = this.videoList.filter(vid => !seenVids.includes(vid.id));
        source = filtered[Math.floor(Math.random() * filtered.length)].id;
        seenVids.push(source);
        sessionStorage.setItem("seenVids", JSON.stringify(seenVids));
      }
  
      loaded(".player").append(`<video id="LRPlayer" muted playsinline autoplay>
      <source src="https://ctt-media.s3.eu-west-2.amazonaws.com/210506_videos/${source}" type="video/mp4" />
      </video>`);
      loaded(".player").append('<div style="width: 100%;height: 100%;position: absolute;z-index: 2;top:0; opacity: 0;"></div><style>.fluid_controls_container{z-index: 3 !important;}</style>')
    }

    

    return loaded.html();
  };

  parseDynamic = async (html) => {
    const options = {
      replace: ({ attribs, type, children, name }) => {
        if (
          type === "script" &&
          !!attribs.src &&
          attribs.src.includes("incontextInjection")
        ) {
          return;
        } else if (type === "script" && !!attribs.src) {
          if (
            attribs.src.includes("gravityforms") ||
            attribs.src.includes("/p.js") ||
            attribs.src.includes("doubleclick") ||
            attribs.src.includes("adgrid.io") ||
            attribs.src.includes("adnxs") ||
            attribs.src.includes("player.zype.com")
          ) {
            return;
          } else if (attribs.src.includes("fluidplayer.min.js")) {
            return (
              <InnerHTML
                html={`<script onload="fluidPlayerReady()" src="${attribs.src}"></script>`}
              />
            );
          } else {
            return (
              <InnerHTML html={`<script src="${attribs.src}"></script>`} />
            );
          }
        } else if (type === "script" && !!children[0]) {
          let scriptContent = children[0].data;
          if (scriptContent.includes("wp") || scriptContent.includes("window.adgrid_gkv")) {
            return;
          } else {
            return (
              <InnerHTML
                html={`<script ${
                  !!attribs.type ? `type="${attribs.type}` : ""
                }">${children[0].data}</script>`}
              />
            );
          }
        } else if (type === "tag" && !!attribs["lumen-href"]) {
          attribs.onClick = () => {
            this.props.pushToHistory(attribs["lumen-href"]);
          };
        } // else if (!!attribs && !!attribs["data-src"]) {
        //   attribs.style = !!attribs.style ? attribs.style + `background-image: url('${attribs["data-src"]}')` : `background-image: url('${attribs["data-src"]}')`
        // }
      },
      trim: true,
    };

    return parse(html, options);
    //return(parse(html))
  };

  parsedRender = async () => {
    let newParsedHtml = await this.parseDynamic(this.state.html);
    this.setState(
      (state) => ({
        ...state,
        parsedHtml: newParsedHtml,
      }),
      () => {
        removeLinks();
      }
    );
  };

  stopTrackingAndVal = () => {
    if (parseInt(sessionStorage.getItem("renderStepOrderId")) > 1) {
      let stepDetails = JSON.parse(
        sessionStorage.getItem("renderSteps")
      ).filter(
        (step) =>
          step.url == window.location.origin + window.location.pathname
      )[parseInt(sessionStorage.getItem("renderStepOrderId"))];
      window.LRStepComplete(
        window.FROM_SERVER.participant_id,
        stepDetails.id,
        window.FROM_SERVER.access_code,
        () => {
          window.LRhandleStepEnd();
        }
      );
    } else {
      window.LRTrackingStop(() => {
        let stepDetails = JSON.parse(
          sessionStorage.getItem("renderSteps")
        ).filter(
          (step) =>
            step.url == window.location.origin + window.location.pathname
        )[parseInt(sessionStorage.getItem("renderStepOrderId"))];
        window.LRStepComplete(
          window.FROM_SERVER.participant_id,
          stepDetails.id,
          window.FROM_SERVER.access_code,
          () => {
            this.props.registerValidProps({});
            this.props.history.push("/validation");
          }
        );
      });
    }
  };

  componentDidMount() {
    document.addEventListener("fluidPlayerReadyEvent", this.activateFluidPlayer);

    // @TODO: add hln.be-select?
    const showNextAfterTimeout = !["2204","2203"].includes(this.props.cachepageId)
    const isHlnBe = this.props.cachepageId === 'hln.be-select';

    if (showNextAfterTimeout) {
      console.log("setting timeout")
      this.timeoutHandler = setTimeout(() => {
        window.postMessage(
          {
            type: "LS_YT_SHOW_NEXT",
            url: window.location.href,
          },
          window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "")
        );
      }, 60000)
    }

    this.getPage({ applyProcessing: isHlnBe }).then((html) => {
      this.setState(
        (state) => ({
          ...state,
          html: html,
        }),
        this.parsedRender
      );
    });
  }

  componentDidUpdate() {
    // register important lumen pointerEvents
    if (!!window.LRRegisterCustomEndHandler && !window.LRCustomEndHandler) {
      window.LRRegisterCustomEndHandler(this.stopTrackingAndVal);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("fluidPlayerReadyEvent", this.activateFluidPlayer)
    if (!!this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      console.log("timeoutCleared");
    }
  }

  render() {
    return (
      <div id="cacheContainer">
        {this.state.parsedHtml ?? null}
      </div>
    );
  }
}

function CachepageWrapper(){
  const params = useParams();
  const [cachepageId, setCachepageId] = useState(params.cachepageId);
  const [remount, setRemount] = useState(false);

  const pushToHistory = (href) => {
    let seenItems = JSON.parse(sessionStorage.getItem("seenItems"));
    if (!seenItems) {
      sessionStorage.setItem("seenItems", JSON.stringify([href.substring(1)]));
    } else {
      seenItems.push(href.substring(1));
      sessionStorage.setItem("seenItems", JSON.stringify(seenItems));
    }
    setCachepageId(href);
    setRemount(true);
  };
  window.pushToHistory = pushToHistory;

  // Fully re-mount the page when cache page ID changes
  // this is a workaround to allow us to re-run getPage
  // without having to make lots of checks
  useEffect(() => setRemount(false), [remount])

  if(remount) {
    return null;
  }

  return <Cachepage cachepageId={cachepageId} pushToHistory={pushToHistory} />
}

export default withRouter(CachepageWrapper);
