import React from 'react';
import {
  useParams,
  withRouter
} from "react-router-dom";
import mobile from 'is-mobile';

class Validation extends React.Component {
  constructor(props) {
    super(props);

    this.validationEl = React.createRef();
  }

  // 1271

  componentDidMount() {
    if (!!window.LRValidate) {
      let valStepDetails = JSON.parse(sessionStorage.getItem('renderSteps')).filter(step => step.type == "ValidationStep")[parseInt(sessionStorage.getItem('renderStepOrderId'))];
      let validStartTime = Date.now();

      const valCb = (success, valData = null) => {
        // submit val data here
        window.LRSubmitValidation(() => {
          window.LRStepComplete(window.FROM_SERVER.participant_id, valStepDetails.id, window.FROM_SERVER.access_code, () => {
            this.props.history.push("/")
            let nextStepDetails = JSON.parse(sessionStorage.getItem('renderSteps')).filter(step => step.url == (window.location.origin + window.location.pathname))[parseInt(sessionStorage.getItem('renderStepOrderId')) + 1];
            window.LRTrackingRestart(nextStepDetails.id, nextStepDetails.order, window.FROM_SERVER.showGazePoints);
          })
        }, valStepDetails.id, valStepDetails.order, validStartTime, success, valData);
      };

      if (window.FROM_SERVER.broker.includes("Mouse")) {
        window.LRValidate(this.validationEl.current, valCb)
      } else {
        window.LRValidate(this.validationEl.current, 60000, valCb)
      }
    } else {
      this.props.history.push("/")
    }
  }

  render() {
    return (
        <div style={{position: "absolute", width: "100%", height: "calc(100% - 50px)"}} ref={this.validationEl}>
        </div>
    );
  }
}

export default withRouter(Validation);
