import React from 'react';
//import { withRouter, Link, useLocation } from "react-router-dom";
import { withRouter, Link } from "react-router-dom";

// function RouteChangeCleanup() {
//   let location = useLocation()
//
//   useEffect(
//     () => {
//       document.querySelectorAll("body > *:not(#root):not(noscript):not([src^='/static/']):not([href*='lumen-research.com']):not([src*='lumen-research.com']):not([id*='lumen'])").forEach(el => el.remove());
//     },
//     [location]
//   )
// }

function List(props) {
  //RouteChangeCleanup()

  return (
    <>
      <h3>Please select a cachepage.</h3>
      <button onClick={() => props.history.push("/1512")}>test</button><br />
      <Link className="lumenAllowedLink" to="/1464">1464</Link><br />
      <Link className="lumenAllowedLink" to="/1278">1278</Link>
    </>
  );
}

export default withRouter(List);
